@font-face {
  font-family: "November m";
  src: url("../../../../public/assets/fonts/NovemberLCG-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_container img {
  width: 150px;
}

nav {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: 0.5s !important;
  background-color: transparent !important;
}

nav.affix {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: white !important;
}

.main_navbar {
  gap: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.main_navbar li a {
  font-weight: 500;
  font-size: 18px;
  color: black;
  font-family: "November m", "helvetica neue", Arial, sans-serif;
}

.li_back {
  color: black;
  font-weight: 900;
}

button.navbar-toggler span {
  color: white;
}

.affix .navbar-toggler span {
  color: black !important;
  opacity: 0.8;
  font-size: 17px;
}

@media screen and (max-width: 1024px) {
  .main_navbar {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 4rem;
  }
}

@media screen and (max-width: 990px) {
  .main_navbar {
    gap: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0rem;
  }

  .main_container img {
    width: 125px;
  }

  .main_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .navbar_toggler {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}


@media screen and (min-width: 2000px) {
  .footer-top {
    padding-top: 125px;
  }
}