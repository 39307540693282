@font-face {
	font-family: 'November l';
	src: url('../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  /* light font */
  @font-face {
	font-family: 'November ex';
	src: url('../../../public/assets/fonts/NovemberLCG-Black.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }



.contact_main{
    margin-top: 4rem;
}
.contact_main h2{
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2;
    font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
}
.contact_main p{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    color: #353535;
    font-weight: 500;
    margin-top: 2rem;
}
.contact_main h6{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    color: #353535;
    font-weight: 500;
}
.contact_main ul li span{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    color: #353535;
    font-weight: 500;
}

@media screen and (max-width:990px) {
    .contact_main{
        margin-top: 0;
    }
    .contact_main h2{
        font-size: 24px;
        font-weight: 900;
        line-height: 1.2;
    }
}