@font-face {
  font-family: "November l";
  src: url("../../../public/assets/fonts/NovemberLCG-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "November ex";
  src: url("../../../public/assets/fonts/NovemberLCG-Black.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* heavy */
@font-face {
  font-family: "November h";
  src: url("../../../public/assets/fonts/NovemberLCG-Heavy.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* medium */
@font-face {
  font-family: "November m";
  src: url("../../../public/assets/fonts/NovemberLCG-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.solution_section {
  padding-top: 50px;
  padding-bottom: 100px;
}

.solution_main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.solution_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solution_left_header {
  width: 100%;
  background-color: #026065;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
}

.solution_left_header_content {
  width: 50%;
  margin: 4rem 0;
  margin-left: 18rem;
  color: white;
  margin-top: 0;
}

.solution_left_header_content h2 {
  color: white;
  font-size: 40px;
  /* Default font size */
  font-weight: 900;
  line-height: 1.2;
  font-family: "November ex", "helvetica neue", Arial, sans-serif;
}

.solution_left_header_content p {
  font-family: "November m", "helvetica neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.solution_right {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8rem;
}

.sol_right_content h5 {
  font-family: "November h", "helvetica neue", Arial, sans-serif;
  font-size: 18.75px;
  font-weight: 600;
  line-height: 28px;
}

.sol_right_content p {
  font-family: "November l", "helvetica neue", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

@media screen and (max-width: 1515px) {
  .solution_main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .solution_left_header_content {
    width: 100%;
    margin: 4rem 0;
    margin-left: 0;
    color: white;
    padding: 0 1rem;
  }

  .solution_left_header {
    padding-top: 0;
  }

  .left_image img {
    max-width: 100%;
    height: auto;
    width: auto;
  }

  .solution_right {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }

  .solution_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .solution_section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .solution_left_header_content h2 {
    color: white;
    font-size: 24px;
    /* Default font size */
    font-weight: 900;
    line-height: 1.2;
    font-family: "November ex", "helvetica neue", Arial, sans-serif;
  }

  .sol_right_content {
    margin-top: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .solution_left_header_content h2 {
    color: white;
    font-size: 18px;
    /* Default font size */
    font-weight: 900;
    line-height: 1.2;
    font-family: "November ex", "helvetica neue", Arial, sans-serif;
  }

  .solution_main {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}