@font-face {
	font-family: 'November ex';
	src: url('../../../public/assets/fonts/NovemberLCG-Black.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }


  .header_img{
    padding-top: 200px;
    padding-bottom: 40px;
  }
 
.header_h1 h1{
    
    font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
    font-size: 52px;
    line-height: 72px;
    font-weight: 700;
    text-align: center;
}

@media screen and (max-width:990px) {
    .header_h1 h1{
    
        font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
        font-size: 30px;
        line-height: 43.2px;
        font-weight: 700;
        text-align: center;
        margin-top: 3rem;
    }
    .header_img{
        padding: 55px 0;
      }
}