.home_section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home_section h1 {
  font-family: "November ex", "helvetica neue", Arial, sans-serif;
  font-size: 52px;
  line-height: 72px;
  font-weight: 900;
  text-align: center;
  width: 50%;
}
@media screen and (max-width: 1024px) {
  .home_section h1 {
    font-family: "November ex", "helvetica neue", Arial, sans-serif;
    font-size: 17px;
    line-height: 30px;
    font-weight: 900;
    text-align: center;
    width: 75%;
  }
}
