@font-face {
	font-family: 'November l';
	src: url('../../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'November h';
	src: url('../../../../public/assets/fonts/NovemberLCG-Heavy.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }


  .footer_main h5{
    font-family: 'November h', 'helvetica neue', Arial, sans-serif;
  }
  .footer_main  ul li{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
  }

  .footer_main p{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
  }
  .footer_bottom ul li{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
  }
  /* .footer_logo img{
    background-color: white;
    border-radius: 50%;
  } */

 