@font-face {
  font-family: "November l";
  src: url("../../../public/assets/fonts/NovemberLCG-Light.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "November ex";
  src: url("../../../public/assets/fonts/NovemberLCG-Black.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
/* heavy */
@font-face {
  font-family: "November h";
  src: url("../../../public/assets/fonts/NovemberLCG-Heavy.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
/* medium */
@font-face {
  font-family: "November m";
  src: url("../../../public/assets/fonts/NovemberLCG-Medium.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.promo p {
  font-family: "November m", "helvetica neue", Arial, sans-serif;
  font-weight: 500;
  color: #323232;
  font-size: 16px;
}
.promo h2 {
  font-size: 40px;
  font-weight: 900;
  line-height: 1.2;
  font-family: "November ex", "helvetica neue", Arial, sans-serif;
}
.promo_cards h5 {
  font-family: "November h", "helvetica neue", Arial, sans-serif;
}
.promo_cards p {
  font-family: "November l", "helvetica neue", Arial, sans-serif;
  font-weight: 500;
  color: #323232;
  font-size: 16px;
}

@media screen and (max-width: 990px) {
  .promo h2 {
    font-size: 24px;
    font-weight: 900;
    line-height: 1.2;
    text-align: start;
  }
  .promo p {
    text-align: start;
  }
  .promo_cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .card {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .promo h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 1.2;
    text-align: start;
  }
}
