/* Fonts */
@font-face {
  font-family: 'November l';
  src: url('../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'November ex';
  src: url('../../../public/assets/fonts/NovemberLCG-Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'November h';
  src: url('../../../public/assets/fonts/NovemberLCG-Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
/* .main_container{
  margin-top: 6rem;
} */

.main_container h2 {
  font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
  font-size: 40px;
  font-weight: 900;
}

.main_container h5 {
  font-family: 'November h', 'helvetica neue', Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.main_container button {
  font-family: 'November l', 'helvetica neue', Arial, sans-serif;
  font-weight: 500;
  margin: 0.2rem;
  font-size: 16px;
  color: #323232;
  outline: none;
  border: 1px solid silver;
  display: inline-block; /* Make spans behave like inline-block elements */
  cursor: pointer; /* Add cursor pointer to indicate interactivity */
  border-radius: 4px; /* Optional: Add border-radius for rounded corners */
  background-color: #fff; /* Optional: Add background color for better visibility */
}

.card{
 
  height: 250px;
}

.main_card{
margin-bottom: 2rem;
}

@media screen and (max-width:990px) {
  .main_container{
    margin-top: 0;
    padding: 0 1rem;
  }
  .main_container h2 {
    font-size: 24px;
   
  }
 
}

@media screen and (max-width:500px) {
  .card{
 
    height: auto;
  }
}