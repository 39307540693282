@font-face {
    font-family: "November l";
    src: url("../../../public/assets/fonts/NovemberLCG-Light.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "November h";
    src: url("../../../public/assets/fonts/NovemberLCG-Heavy.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  /* black */
  @font-face {
    font-family: "November ex";
    src: url("../../../public/assets/fonts/NovemberLCG-Black.woff2")
      format("woff2");
    font-weight: normal;
    font-style: normal;
  }
  
  .home_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home_section h1 {
    font-family: "November ex", "helvetica neue", Arial, sans-serif;
    font-size: 52px;
    line-height: 72px;
    font-weight: 900;
    text-align: center;
    width: 50%;
  }
  .single_blog h5 {
    margin-top: 1rem;
    margin-bottom: 0;
    padding-bottom: 0;
    font-family: "November h", "helvetica neue", Arial, sans-serif;
    font-weight: 900;
  }
  .single_blog h4 {
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 1rem;
    font-family: "November h", "helvetica neue", Arial, sans-serif;
    font-weight: 900;
  }
  .single_blog p {
    margin-top: 0;
    padding-top: 0;
    font-family: "November l", "helvetica neue", Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #353535;
    /* margin-bottom: 4rem; */
  }
  .post_tag h4 {
    margin-bottom: 0;
    padding-bottom: 0;
    font-family: "November h", "helvetica neue", Arial, sans-serif;
    font-weight: 900;
  }
  
  @media screen and (max-width: 1024px) {
    .home_section h1 {
      font-family: "November ex", "helvetica neue", Arial, sans-serif;
      font-size: 36px;
      line-height: 65px;
      font-weight: 900;
      text-align: center;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 767px) {
    .home_section h1 {
      font-family: "November ex", "helvetica neue", Arial, sans-serif;
      font-size: 20px;
      line-height: 30px;
      font-weight: 900;
      text-align: center;
      width: 75%;
    }
  }
  