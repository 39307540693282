@font-face {
	font-family: 'November l';
	src: url('../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  /* heavy */
  @font-face {
	font-family: 'November h';
	src: url('../../../public/assets/fonts/NovemberLCG-Heavy.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  
  @font-face {
	font-family: 'November ex';
	src: url('../../../public/assets/fonts/NovemberLCG-Black.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }



.news h2{
    font-size: 40px;
    font-weight: 900;
    line-height: 1.2;
    font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
}
.news p{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #353535;
}
.new_h3 h3{
    font-family: 'November h', 'helvetica neue', Arial, sans-serif;
    font-size: 18.7px;
    font-weight: 500;
}

@media screen and (max-width:1024px) {
    .news h2{
        font-size: 24px;
        font-weight: 900;
        line-height: 1.2;
    }
    .new_h3{
      flex-direction: column;
    }
    .news{
      padding: 0 2rem;
    }
}