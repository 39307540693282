/* FeatureImgTwo.module.css */

@font-face {
	font-family: 'November l';
	src: url('../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  /* light font */
  @font-face {
	font-family: 'November ex';
	src: url('../../../public/assets/fonts/NovemberLCG-Black.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }

  .insight_main{
    /* display: grid; */
    grid-template-columns: auto auto;
    gap: 1rem;
    padding-left: 2rem;
    margin-top: 0rem;
    padding-bottom: 0px;
  }
  .insight_left{
    padding:  0 2rem;
  }
  .insight_left h2{
    font-size: 40px; /* Default font size */
    font-weight: 900;
    line-height: 1.2;
    font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
  }
  .insight_left p{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  }
  .insight_right img{
    height: auto;
    width: auto;
    max-width: 100%;
    margin-top: 6rem;
  }

@media screen and (max-width:1717px) {
  .insight_main{
    display: grid;
        grid-template-columns: auto;
        margin-top: 0;
        padding-left: 0;
        padding-bottom: 0;
  }
  .insight_right{
    display: flex;
        justify-content: center;
        align-items: center;  
        margin-top: 0; 
        /* margin-bottom: 4rem; */
  }
  .insight_right img{
    width: 75%;
  }
}



@media screen and (max-width:990px) {
  .insight_left{
    padding: 0 1rem;
  }
  .insight_right img{
    width: 100%;
  }
  .insight_left h2{
    font-size: 24px; /* Default font size */
    font-weight: 900;
    line-height: 1.2;
    font-family: 'November ex', 'helvetica neue', Arial, sans-serif;
  }

}