@font-face {
	font-family: 'November l';
	src: url('../../../public/assets/fonts/NovemberLCG-Light.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }
  /* light font */
  @font-face {
	font-family: 'November h';
	src: url('../../../public/assets/fonts/NovemberLCG-Heavy.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
  }

.faq_main h5{
    font-family: 'November h', 'helvetica neue', Arial, sans-serif;
} 
.faq_main p{
    font-family: 'November l', 'helvetica neue', Arial, sans-serif;
    font-weight: 500;
    color: #353535;
}





@media screen and (max-width:990px) {
    .faq_main h5{
        padding-left: 0.5rem;
    }
    .faq_main p{
        padding-left: 0.5rem;
    }
}